import { ChainId, SUPPORTED_CHAINS, SupportedChainsType } from './chains'

type AddressMap = { [chainId: number]: string }

type ChainAddresses = {
  factoryAddress: string
  multicallAddress: string
  quoterAddress: string
  orderBookAddress?: string
  nonfungiblePositionManagerAddress?: string
  tickLensAddress?: string
  swapRouterAddress?: string
  v1MixedRouteQuoterAddress?: string
  lockAddress?: string
  tradeRouter?: string
  voterAddress?: string
  rougexTokenAddress?: string
  faucetAddress?: string
  minterAddress?: string
  tradeReaderAddress?: string
  rewardsDistributorAddress?: string
  roxUtilsAddress?: string
}

const DEFAULT_NETWORKS = [ChainId.ARBITRUM_ONE]

function constructSameAddressMap(address: string, additionalNetworks: ChainId[] = []): AddressMap {
  return DEFAULT_NETWORKS.concat(additionalNetworks).reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = address
    return memo
  }, {})
}

const ARBITRUM_ONE_ADDRESSES: ChainAddresses = {
  factoryAddress: '0x2140c5B4ef9Cc6d18196C43eeFA9dfF3595467A2',
  tradeRouter: '0xBCcDa22DB89AA35Eff735c433AE0f2642dd3Da07',
  nonfungiblePositionManagerAddress: '0x5Cf3679187B6096a990393bBE67Ec191e3fD7416',
  swapRouterAddress: '0x064d6286b4A9702a85e934AECac2407eb509F34a',
  quoterAddress: '0xA13f5A14c384F550c7168453c1EE0B30F10dc4d0',
  tickLensAddress: '0xE58E92B0A47F29c38A919527099B4d5f7eff6d43',

  roxUtilsAddress: '0x586536752bD0d3D767440E73FdBa2D4016990E2F',
  tradeReaderAddress: '0xE48f2C155e4Cc7D7d2aFBEEc11ce85088e7d5D90',
  orderBookAddress: '0x9D0DF48c859942cFd437F1A5e8e5f8980118e445',

  lockAddress: '0xCD3B42917b4d1f896F0735C1D6EDd44304548Fa4',

  rougexTokenAddress: '0x1F4D8C5f5cb85E923Bff7D73bf08795E68d4b648',
  rewardsDistributorAddress: '0x0d0E982AE77DE7bcd59f9Bd9bf19BBa6d2068964',

  voterAddress: '0x03534749642F4545233CCFDA3c1074fDc8a5511e',
  minterAddress: '0xF73e7422fBBdB4CB4252D0eF3724D9eb6967Aca3',

  multicallAddress: '0x027EDCF231957833e95Bf6131E61EA8345d580d3',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

// eslint-disable-next-line multiline-comment-style
// arbitrum goerli v3 addresses
const GOERLI_ADDRESSES: ChainAddresses = {
  factoryAddress : '0x0149Ae85D16f396A8cc34445876a304097910639',
  tradeRouter: '0xe32A514D929Daa0A9b3853C71bD0e22d80679CBA',
  nonfungiblePositionManagerAddress : '0xDC3e6cA428F36EEfBb6eE2c40AD2E84FDEe2Db19',
  swapRouterAddress : '0x63238B8e910288f6aB3C8Eebef207d0C7b12b66e',
  quoterAddress : '0x4082541102D020f5aC0a9677E2d7dcAa0BcCB631',
  tickLensAddress : '0x6f91826dAb2aFFD602A97EFeAc8Ba99FA9226062',
  roxUtilsAddress : '0xb92C858f8208C82bE209d347b378BF7d63eBb15F',
  tradeReaderAddress: '0xD4f02851F730e8Ad23560C8d02236E7904885D43',
  orderBookAddress: '0xd95E11E8288F94D5E7eF14DB3f7a7F4D85b5b6fD',

  multicallAddress: '0x1F98415757620B543A52E61c46B32eB19261F984',

  lockAddress: '0x47Cd8AAae6AaDa375AB712bD2340aBD05d403566',
  rougexTokenAddress: '0x3d3fADCbeba5e68a35581681b6a2D650DAd292f4',
  rewardsDistributorAddress: '0x7F80E24A4C482584605c592908fe3278732F5838',

  voterAddress: '0x86395AB62F130E77D1CF237C6d6EBa8F6A7DDc3d',
  minterAddress: '0x55DF366DAb41B8207c7060b9ae67D257bB9A655D',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}

const Sepolia_ADDRESSES: ChainAddresses = {
  factoryAddress: '0xa55F2744DA15cD95822E8e0958305C58e32F9a98',
  tradeRouter: '0xE84594F5B8740E428405f6928C493ef63A318dbA',
  nonfungiblePositionManagerAddress: '0x0Fe7ACf5a758144536e415540e560650B79F6214',
  swapRouterAddress: '0xf8D61E10845893c12B9C495e01976C43F957b529',
  quoterAddress: '0x86eFaA010f65B17dD7504fA7450d56aEcC77bcAD',
  tickLensAddress: '0x4Eb761FCdEC79825b50d7572AdC02601b453639b',
  roxUtilsAddress: '0x75B801637DBB14E59B6Bf378b558601B7B219057',
  tradeReaderAddress: '0xF18F2234823130C50EeD4736893a328Ab4ab54D0',
  orderBookAddress: '0x06422f49ab551c6D7e1ad30af4B7A80f5712d13d',

  multicallAddress: '0x532a5B1A2F8ccE4f05F8b2a9A262c7097B4356ec',

  lockAddress: '0x48878653673113bD4DbEd485a34E194735BA7E10',
  rougexTokenAddress: '0x00fA700a16eF7B3c968Bb1dCD4C6bA697bA73fb9',
  rewardsDistributorAddress: '0x20B7ab1AD1517Bd17cD2F4dc5C29920Bff92ba82',

  voterAddress: '0x780A9Fa3B434b99A2f7751B00c6fC269f19871Bd',
  minterAddress: '0x918891120A62B0F2a91012a8De9f10Dc30a9E3Cd',

  faucetAddress: '0xc99ba80D87c156b90c8AEEe4fa33658df14E14F5',
}
/*
{
    "Reader" : "0xbC166B69128F7a2540ffBe8dF7FA20aA10A656EC",
    "AlgebraInterfaceMulticall" : "0xB2f67eA43943e6C14580B9ECc88B3b05969eD4b3",
   

    "rougeX" : "0x03Ac42E4F1824CCF78c3F31Cbf590A7f2BF836F4",
    "RoguexFactory" : "0x3E2F8Cf4701a073662be8F9689672e14601EF8E7",
    "RoxUtils" : "0x043B339b34D6c70e6620Fe7348C145e659B84ad7",
    "RoxSpotPoolDeployer" : "0xE9cD4a7134C2DD230A84321005639A79701c46B2",
    "RoxPerpPoolDeployer" : "0x08F737885109DF82E060E8dd70ffA3abc0374304",
    "RoxPosnPoolDeployer" : "0x892d82BB2446B7E28EBf099884955A18e2ca8a75",
    "NonfungiblePositionManager" : "0xB913077bf6818C97D2d5bAB37035f6C73a8Ca19A",
    "Quoter" : "0xcFe1b7ee1c6d6E1BAA89c11dEeC8d68E34457b36",
    "TickLens" : "0xB58F787811AA3f9f47Bfa2e2F3f64FFE34b337a7",
    "PerpRouter" : "0xc668e9C19D61f2B43539cdeDbd56F0074569F635",
    "SwapRouter" : "0x5AE969c478da28FCDE4ED8bb8a8E13F05a7eacf0",
    "PerpOrderbook" : "0xe7bef061e90b16D8eC23b138538D6E16d84ddA84",
    "VeArtProxy" : "0xFfC0E222649e4F6DD5C4013598A5b74D15738d32",
    "VotingEscrow" : "0xf600430dfA536846ddCe797d14D363F02BCf0E4a",
    "RewardsDistributor" : "0x9765645932C42a3bA0914DD11Be47BA7293ad7Dc",
    "MasterChefFactory" : "0x4201C2fCC1F43Ec35b5b0063214033c87dF29eF7",
    "SwapMinningFactory" : "0x299DC8e05135Ae17344ea407DE73D3738Db22780",
    "VotingRewardFactory" : "0x8B6169AAe587baFcF99E0b6a3511b69C37Cc6CFA",
    "Voter" : "0xDD21B5AA6F2060a7F1EC8Bb9866162293E5ED89e",
    "Minter" : "0x31Fb8927A18fbfBF91212Abf22a32B69145faD3A",
    "DisFeeReward" : "0x7027971462E497660c98a9996BdF1Ce5a311e10b",
    "HypervisorFactory" : "0x53AC863e8A16258eBeb70257991e46BF41fc07c1",




    "deployTime" : "2024.01.24"
}
*/
const BLAST_ADDRESSES: ChainAddresses = {
  factoryAddress : '0x5006828d145df20323f7B1cD7F69983C87dd4F3e',
  tradeRouter: '0x82408165e1062c9326601DF0e1b9136586E67ab4',
  nonfungiblePositionManagerAddress : '0xeacc87ed173DB5ecC7DfcF107e725247245c635b',
  swapRouterAddress : '0xFbd27Dd3Fd322C7Ee4C35145F840f26554209FD0',
  quoterAddress : '0xa1645960979EC186cD7E702a0cCa4355cdCD631C',
  tickLensAddress : '0x87D27C3484F634dF7B23162D6Bce723960634AC8',
  roxUtilsAddress : '0x99cf20b1E939bd1B6eAfAbf0F2E7C872541668e9',
  tradeReaderAddress: '0x70BF6018bcE66819946501eB240a46cD0Ea7caE6',
  orderBookAddress: '0xa39c792CB19aFA51975750BcFC1a166a71Fd54c8',

  multicallAddress: '0x1eF53C45b070191cBD7C5Fc88e2Ddafaa29DE1a9',

  lockAddress: '0xffdAB5073aaa2fd574F636C91EA2bD07648f7059',
  rougexTokenAddress: '0x03Ac42E4F1824CCF78c3F31Cbf590A7f2BF836F4',
  rewardsDistributorAddress: '0xA995B944504736F53E0ef6e83603E49fD8D8bb07',

  voterAddress: '0x1634F78e934050b5FF043a84247DD44A1EA24a8B',
  minterAddress: '0x5ab7891E1D56DB40Ce37057433eE162d3Baae7a5',

  faucetAddress: '0x2516F4Cb4De50CC62B8705d4067C42b451b5A36c',
}

const CHAIN_TO_ADDRESSES_MAP: Record<SupportedChainsType, ChainAddresses> = {
  [ChainId.ARBITRUM_ONE]: ARBITRUM_ONE_ADDRESSES,
  [ChainId.GOERLI]: GOERLI_ADDRESSES,
  [ChainId.ARBITRUM_SEPOLIA]: Sepolia_ADDRESSES,
  [ChainId.BLAST_TEST]: BLAST_ADDRESSES,
}

/* V3 Contract Addresses */
export const V3_CORE_FACTORY_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].factoryAddress
    return memo
  }, {}),
}

export const MULTICALL_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].multicallAddress
    return memo
  }, {}),
}

export const ARGENT_WALLET_DETECTOR_ADDRESS: AddressMap = {}

export const QUOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    memo[chainId] = CHAIN_TO_ADDRESSES_MAP[chainId].quoterAddress
    return memo
  }, {}),
}

export const NONFUNGIBLE_POSITION_MANAGER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const nonfungiblePositionManagerAddress = CHAIN_TO_ADDRESSES_MAP[chainId].nonfungiblePositionManagerAddress
    if (nonfungiblePositionManagerAddress) {
      memo[chainId] = nonfungiblePositionManagerAddress
    }
    return memo
  }, {}),
}

export const ENS_REGISTRAR_ADDRESSES: AddressMap = {
  ...constructSameAddressMap('0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e'),
}

export const TICK_LENS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tickLensAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tickLensAddress
    if (tickLensAddress) {
      memo[chainId] = tickLensAddress
    }
    return memo
  }, {}),
}
export const SWAP_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const swapRouter02Address = CHAIN_TO_ADDRESSES_MAP[chainId].swapRouterAddress
    if (swapRouter02Address) {
      memo[chainId] = swapRouter02Address
    }
    return memo
  }, {}),
}
export const TRADE_ROUTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeRouter = CHAIN_TO_ADDRESSES_MAP[chainId].tradeRouter
    if (tradeRouter) {
      memo[chainId] = tradeRouter
    }
    return memo
  }, {}),
}

export const LOCK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const lockAddress = CHAIN_TO_ADDRESSES_MAP[chainId].lockAddress
    if (lockAddress) {
      memo[chainId] = lockAddress
    }
    return memo
  }, {}),
}

export const VOTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const voterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].voterAddress
    if (voterAddress) {
      memo[chainId] = voterAddress
    }
    return memo
  }, {}),
}

export const MINTER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const minterAddress = CHAIN_TO_ADDRESSES_MAP[chainId].minterAddress
    if (minterAddress) {
      memo[chainId] = minterAddress
    }
    return memo
  }, {}),
}

export const ROUGEX_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rougexTokenAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rougexTokenAddress
    if (rougexTokenAddress) {
      memo[chainId] = rougexTokenAddress
    }
    return memo
  }, {}),
}

export const FAUCET_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const faucetAddress = CHAIN_TO_ADDRESSES_MAP[chainId].faucetAddress
    if (faucetAddress) {
      memo[chainId] = faucetAddress
    }
    return memo
  }, {}),
}
export const TRADEREADER_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const tradeReaderAddress = CHAIN_TO_ADDRESSES_MAP[chainId].tradeReaderAddress
    if (tradeReaderAddress) {
      memo[chainId] = tradeReaderAddress
    }
    return memo
  }, {}),
}

export const REWARDS_DISTRIBUTOR_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const rewardsDistributorAddress = CHAIN_TO_ADDRESSES_MAP[chainId].rewardsDistributorAddress
    if (rewardsDistributorAddress) {
      memo[chainId] = rewardsDistributorAddress
    }
    return memo
  }, {}),
}

export const ROX_UTILS_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const roxUtilsAddress = CHAIN_TO_ADDRESSES_MAP[chainId].roxUtilsAddress
    if (roxUtilsAddress) {
      memo[chainId] = roxUtilsAddress
    }
    return memo
  }, {}),
}

export const ORDER_BOOK_ADDRESSES: AddressMap = {
  ...SUPPORTED_CHAINS.reduce<AddressMap>((memo, chainId) => {
    const bookAddress = CHAIN_TO_ADDRESSES_MAP[chainId].orderBookAddress
    if (bookAddress) {
      memo[chainId] = bookAddress
    }
    return memo
  }, {}),
}
